import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import Dropdown from '../components/Dropdown'
import MuiField from './../components/MuiField';
import Div from './../components/Div/Div.styles';
import Button from './../components/Button/Button';
import Overline from '../components/Overline'
import { 
  styledSslCertificateRequest,
  header,
} from '../components/styles/SslCertificateRequest.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledSslCertificateRequest}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>Pedido de certificado</span>
              Importante
            </H4>
            <Paragraph>Será necessário que crie uma conta de e-mail <b>admin@seudominio.com</b> e nos informe os dados de acesso, pois a mesma é utilizada para validar o Certificado. Caso esta conta já exista basta nos informar a senha de acesso.</Paragraph>
          </Column>
        </Row>
        <Overline
          selected
          on='onSurface'
          variant='highEmphasis'
          mb={[3]}
          align='center'
        >
          informações de contato
        </Overline>
        <Row
          mb={[15, 15, 36]}
          flexDirection={['column', 'column', 'row']}
        >
          <Column
            width={[1, 1, 1/2, 1/2]}
          >
            <Div
              alignItems={['center', 'center', 'flex-end']}
              display={['flex']}
            >
              <MuiField
                variant="outlined"
                label="Nome Completo"
                placeholder='Nome Completo'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Email"
                placeholder='Email'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
            </Div>
          </Column>
          <Column
            width={[1, 1, 1/2, 1/2]}
          >
            <Div
              alignItems={['center', 'center', 'flex-start']}
            >
              <MuiField
                variant="outlined"
                label="Telefone"
                placeholder='Telefone'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
            </Div>
          </Column>
        </Row>
        <Overline
          selected
          on='onSurface'
          variant='highEmphasis'
          mb={[3]}
          align='center'
        >
          informações para o certificado
        </Overline>
        <Row
          mt={[0, 0, 40]}
          flexDirection={['column', 'column', 'row']}
        >
          <Column
            width={[1, 1, 1/2, 1/2]}
          >
            <Div
              alignItems={['center', 'center', 'flex-end']}
              display={['flex']}
            >
              <MuiField
                variant="outlined"
                label="Domínio"
                placeholder='Domínio'
                style={{
                  maxWidth: 328,
                  marginBottom: 17
                }}
                caption='com ou sem wwww'
              />
              <MuiField
                variant="outlined"
                label="Endereço"
                placeholder='Endereço'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Estado"
                placeholder='Enstado'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Cidade"
                placeholder='Cidade'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="CEP"
                placeholder='CEP'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
            </Div>
          </Column>
          <Column
            width={[1, 1, 1/2, 1/2]}
          >
            <Div
              alignItems={['center', 'center', 'flex-start']}
            >
              <MuiField
                variant="outlined"
                label="Nome da Empresa"
                placeholder='Nome da Empresa'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Divisão da Empresa"
                placeholder='Divisão da Empresa'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <Dropdown
                options={[
                  { label: 'Opção 1', value: 0 }
                ]}
                opaque
                label='Pacote SSL'
                style={{
                  maxWidth: 328,
                  marginBottom: 10
                }}
              />
              <MuiField
                variant="outlined"
                label="Senha"
                placeholder='Senha'
                style={{
                  maxWidth: 328,
                  marginBottom: 17
                }}
                caption='senha do email admin@seudominio.com'
              />
            </Div>
          </Column>
        </Row>
        <Row
          mb={30}
        >
          <Column
            width={[1]}
            display={['flex']}
            justifyContent={['center']}
          >
            <Div
              maxWidth={[328, 328, 680, 680]}
              style={{
                width: '100%'
              }}
            >
              <MuiField
                variant="outlined"
                label="Comentários"
                placeholder='Comentários'
                multiline
                rows={6}
                style={{
                  width: '100%'
                }}
                InputProps={{
                  style: {
                    width: '100%'
                  }
                }}
              />
            </Div>
          </Column>
        </Row>
        <Div
          style={{
            marginBottom: 50
          }}
        >
          <Button
            schema='secondary'
            color='white'
            variant='highEmphasis'
            width={['100%']}
            maxWidth={['160px']}
            style={{
              display: 'flex',
              border: 'none',
              margin: '0 auto',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
            }}
          >
            Enviar
          </Button>
        </Div>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nossos Serviços' />
      <PageTemplate
        title='Nossos Serviços'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
