import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledSslCertificateRequestBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledSslCertificateRequest = css`
  ${styledSslCertificateRequestBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
    b {
      font-weight: bold;
    }
  }
  img {
    margin-bottom: 24.5px;
  }
`